import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import AdminContainer from '../../../layout/admin/container';
import { useBreadcrumbs } from '../../../../contexts/breadcrumbs-provider';
import Loadable from '../../../data/loadable';
import Heading from '../../../layout/admin/heading';
import { getCenterAdminToken } from '../../../../utils/auth';
import { getApiRequest, postApiRequest } from '../../../../utils/request';
import SEO from '../../../layout/seo';
import Table from '../../../data/table';
import { findIndexById, orderTeamsByLanes } from '../../../../utils/helpers';
import { useNotifications } from '../../../../contexts/notifications-provider';

function InputCell({ onChange, name, value, placeholder = '' }) {
  return (
    <input
      onChange={(e) => onChange(name, e.target.value)}
      value={value}
      className={`block w-full min-w-[120px] rounded-md text-xs shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300`}
      id={name}
      name={name}
      placeholder={placeholder}
      type="number"
    />
  );
}

function SaveButton({ onSave, label = 'Save Game' }) {
  const colorStyles = 'min-w-max inline-flex items-center rounded border border-transparent bg-blue-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2';
  return (
    <button
      type="button"
      className={colorStyles}
      onClick={onSave}
    >
      {label}
    </button>
  );
}

function ColumnButton({ label, gameKey, isActive, setActive }) {
  const colorStyles = 'min-w-max inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2';
  const whiteStyles = 'min-w-max inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2';
  return (
    <button
      type="button"
      className={isActive ? whiteStyles : colorStyles}
      onClick={() => setActive(gameKey)}
    >
      {label}
    </button>
  );
}

function RowButton({ label, teamKey, isActive, setActive }) {
  const colorStyles = 'min-w-max inline-flex items-center rounded border border-transparent bg-indigo-500 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:ring-offset-2';
  const whiteStyles = 'min-w-max inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:ring-offset-2';
  return (
    <button
      type="button"
      className={isActive ? whiteStyles : colorStyles}
      onClick={() => setActive(teamKey)}
    >
      {label}
    </button>
  );
}

function ScorersTable({ numGames, scores, teams, updateScores }) {
  const [activeGame, setActiveGame] = useState(null);
  const [activeTeam, setActiveTeam] = useState(null);
  const [localScores, setLocalScores] = useState(null);

  useEffect(() => {
    setLocalScores({...scores});
  }, [scores]);

  const saveLocalScore = (name, score) => {
    if (score < 0 || score > 300) {
      alert(`The score ${score} is out of bounds, did you make a typo?`);
      return;
    }
    const newLocalScore = {...localScores};
    newLocalScore[activeGame][name] = score;
    setLocalScores(newLocalScore);
  };

  const saveLocalScoreForTeam = (game, score) => {
    if (score < 0 || score > 300) {
      alert(`The score ${score} is out of bounds, did you make a typo?`);
      return;
    }
    const newLocalScore = {...localScores};
    newLocalScore[game][activeTeam] = score;
    setLocalScores(newLocalScore);
  };
  
  const handleActiveGame = (id) => {
    setActiveTeam(null);
    if (activeGame === id) {
      setActiveGame(null);
    } else {
      setActiveGame(id);
    }
  };
  const handleActiveTeam = (id) => {
    setActiveGame(null);
    if (activeTeam === id) {
      setActiveTeam(null);
    } else {
      setActiveTeam(id);
    }
  };


  if (!numGames || !teams || !teams.length || !localScores) {
    return null;
  }

  // Translate to a table
  const columns = [{
    id: 'team',
    key: 'team',
    label: 'Team',
    isBold: true,
  }];
  for (let i = 1; i <= numGames; i++) {
    const gameKey = `game${i}`;
    const isActive = activeGame === gameKey;
    columns.push({
      id: gameKey,
      key: gameKey,
      isHighlighted: isActive,
      label: <ColumnButton label={`Game ${i}`} gameKey={gameKey} isActive={isActive} setActive={handleActiveGame} />,
    });
  }
  columns.push({
    id: 'teamsave',
    key: 'teamsave',
    label: ' ',
  });
  const rows = [];
  const lastRow = {
    id: '_last',
    team: '',
  };
  teams.forEach(team => {
    const teamKey = `team${team.id}`;
    const isTeamActive = activeTeam === teamKey;
    const row = {
      id: team.id,
      team:  <RowButton label={team.name} teamKey={teamKey} isActive={isTeamActive} setActive={handleActiveTeam} />,
    };
    for (let i = 0; i < columns.length; i++) {
      const col = columns[i];
      const gameKey = col.key;
      if (gameKey !== 'team' && gameKey !== 'teamsave') {
        const isActive = activeGame === gameKey || activeTeam === teamKey;
        const existingScore = !!localScores && !!localScores[gameKey] ? localScores[gameKey][teamKey] : '';
        row[gameKey] = isActive ? <InputCell placeholder={team.name} onChange={isTeamActive ? saveLocalScoreForTeam : saveLocalScore} value={existingScore} name={isTeamActive ? gameKey : teamKey} /> :  existingScore === '' ? '--' : existingScore;
        lastRow[gameKey] = !activeTeam && isActive ? <SaveButton onSave={() => updateScores(localScores)} /> : '';
      }
      if (gameKey === 'teamsave') {
        row.teamsave = isTeamActive ? <SaveButton label="Save Team Scores" onSave={() => updateScores(localScores)} /> : '';
        if (isTeamActive) {
          row._isHighlightedRow = true;
        }
      }
    }
    rows.push(row);
  });
  // Last row for save button!
  rows.push(lastRow);

  // console.log(localScores, 'local scores...');

  return (
    <div className="px-0 md:px-8 my-8 max-w-max">
      <div className="bg-white overflow-hidden shadow rounded-lg border border-gray-200">
        <Table columns={columns} rows={rows} />
      </div>
    </div>
  );
}

function LeaderBoard({ leaderboard, teams }) {
  if (!leaderboard) {
    return null;
  }

  // Translate to a table
  const columns = [{
    id: 'position',
    key: 'position',
    label: ``,
  }];
  const rows = [];
  leaderboard.forEach((gameScores, i) => {
    const { gameKey, label } = gameScores;
    columns.push({
      id: gameKey,
      key: gameKey,
      label,
    });
  });
  // each row is position, game1, game2, game3 etc, for the spot in the scores array
  for (let i = 0; i < teams.length; i++) {
    const row = {
      id: i,
      position: `${i + 1}.`,
    };
    leaderboard.forEach((game) => {
      const { gameKey, scores } = game;
      const info = scores[i];
      const { name, points, score } = info;
      const value = score === '' ? '--' : <span className="text-xs">{name}<br /><span className="font-bold">{points === 0 ? 0 : `+${points}`}</span></span>;
      row[gameKey] = value;
    });
    rows.push(row);
  }

  // console.log(localScores, 'local scores...');

  return (
    <div className="px-0 md:px-8 my-8 max-w-max">
      <h2 className="mb-2 font-semibold text-lg">Leaderboard</h2>
      <div className="bg-white overflow-hidden shadow rounded-lg border border-gray-200">
        <Table columns={columns} rows={rows} />
      </div>
    </div>
  );
}

function TotalScoreboard({ leaderboard, calculatedSortedTeamScores }) {
  if (!leaderboard) {
    return null;
  }

  // Translate to a table
  const columns = [
    {
      id: 'team',
      key: 'team',
      label: `Team`,
    },
    {
      id: 'points',
      key: 'points',
      label: `Points`,
      isBold: true,
      isRight: true,
    },
  ];
  const rows = [];
  const sortedTeamScores = calculatedSortedTeamScores(leaderboard);
  // each row is position, name, points etc, for the spot in the scores array
  for (let i = 0; i < sortedTeamScores.length; i++) {
    const info = sortedTeamScores[i];
    const { name, points } = info;
    const row = {
      id: i,
      team: name,
      points,
    };
    rows.push(row);
  }

  // console.log(localScores, 'local scores...');

  return (
    <div className="px-0 md:px-8 my-8 max-w-max">
      <h2 className="mb-2 font-semibold text-lg">Team Totals</h2>
      <div className="bg-white overflow-hidden shadow rounded-lg border border-gray-200">
        <Table columns={columns} rows={rows} />
      </div>
    </div>
  );
}

const CenterAdminScoringApp = ({ productId, week }) => {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [scores, setScores] = useState(null);
  const [leaderboard, setLeaderboard] = useState(null);
  const [teams, setTeams] = useState(null);
  const { setBreadcrumbs } = useBreadcrumbs();
  const { addNotification } = useNotifications();

  const loadProductAndTeams = async () => {
    setLoading(true);
    try {
      const result = await getApiRequest(`/center-admin/product/${productId}?password=${getCenterAdminToken()}`);
      setProduct(result);
      const teamResult = await getApiRequest(`/center-admin/product/${productId}/teams?password=${getCenterAdminToken()}`);
      const orderedTeams = orderTeamsByLanes(result, teamResult.teams || []);
      setTeams(orderedTeams);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        return;
      }
      addNotification({
        type: 'error',
        body: 'There was an error loading the league for the scoring app.',
      });
      navigate('/app/center-admin/dashboard');
    }
    setLoading(false);
  };


  useEffect(() => {
    setLoading(true);
    setBreadcrumbs([
      {
        href: '/app/center-admin/dashboard',
        label: 'Dashboard',
      },
      {
        href: `/app/center-admin/scoring/scoreboard?product_id=${productId}`,
        label: 'Scoreboard',
      },
      {
        href: `/app/center-admin/scoring/app?product_id=${productId}&week=${week}`,
        label: `Week ${week}${week === '1' ? ' (Practice)' : ''} Scores`,
      },
    ]);
    
    loadProductAndTeams();
  }, []);



  // Attempt to get games from product, then league, then fallback
  const numGames = product && product.num_games ? product.num_games : product && product.league && product.league.num_games ? product.league.num_games : 8;

  const loadScores = async () => {
    if (!week || !productId) {
      return;
    }
    setLoading(true);
    try {
      const result = await getApiRequest(`/center-admin/scoring/scores/${productId}/week/${week}?password=${getCenterAdminToken()}`);
      const scoresPerGame = {};
      for (let i = 1; i <= numGames; i++) {
        const gameKey = `game${i}`;
        scoresPerGame[gameKey] = {};
      }
      // Transform our scores to match what the client expects ({ game1: { team1: '20', team2: '', team4: '0', etc }})
      if (result?.scores) {
        result.scores.forEach(score => {
          const gameKey = `game${score.game}`;
          const teamKey = `team${score.team_id}`;
          if (!!scoresPerGame[gameKey]) {
            scoresPerGame[gameKey][teamKey] = score.score === null ? '' : `${score.score}`;
          }
        });
      }

      const newScores = {};
      for (let i = 1; i <= numGames; i++) {
        const gameKey = `game${i}`;
        const existingScoresForGame = scoresPerGame[gameKey];
        const teamScores = {};
        teams.forEach((team) => {
          const teamKey = `team${team.id}`;
          teamScores[teamKey] = existingScoresForGame[teamKey] || '';
        });
        newScores[gameKey] = teamScores;
      }
      setScores(newScores);

    } catch (err) {
      console.log(err, 'error loading scores');
    }
    setLoading(false);
    
  };

  useEffect(() => {
    if (!week || !teams) {
      return;
    }
    loadScores();
  }, [week, teams]);
  


  // SCORING IS COMPLICATED!!!
  // Everyone gets 1 point for participating, fine, ignoring that
  // However! You get 1 point for every team you beat!  If you TIE, you add up the teams in the TYING POSITIONS and divide by the number of TIES
  // So if there are 3 teams tied at the top with 200 points, they get 9 + 8 + 7 / 3. Same for any other position.

  // WHEN CALCULATING SCORES, teams that have NULL as their value get 0 NO MATTER WHAT.

  const calculatedSortedTeamScores = (leaderboard) => {
    const teamScores = [];
    leaderboard.forEach((gameScores, i) => {
      const { scores } = gameScores;
      scores.forEach(info => {
        const { id, name, points } = info;
        const teamScoresIndex = findIndexById(teamScores, info.id);
        if (teamScoresIndex === -1) {
          teamScores.push({ name, points, id });
          return;
        }
        teamScores[teamScoresIndex].points += points;
      });
    });

    return teamScores.sort((s1, s2) => s2.points - s1.points);
  }

  const calculateScoresWithPointsForGame = (gameScores) => {
    const scoresForGame = [];
    teams.forEach((team) => {
      const teamKey = `team${team.id}`;
      const score = !!gameScores ? gameScores[teamKey] : '';
      scoresForGame.push({
        teamKey,
        ...team,
        score,
      });
    });
    // Sort the scores
    const sortedScoresForGame = scoresForGame.sort((s1, s2) => {
      const numericScore1 = s1.score === '' ? -1 : parseFloat(s1.score);
      const numericScore2 = s2.score === '' ? -1 : parseFloat(s2.score);
      return numericScore2 - numericScore1;
    });
    // console.log('sorted scores just before...', sortedScoresForGame);
    // Calculate the points awarded for each team
    const totalTeams = teams.length;
    teams.forEach(team => {
      const indexInScoringList = findIndexById(sortedScoresForGame, team.id);
      const myScore = sortedScoresForGame[indexInScoringList];
      // Null always results in NO POINTS for the team
      if (myScore.score === '') {
        sortedScoresForGame[indexInScoringList].points = 0;
        return;
      }
      let pointsForTeam = 1;
      // First, get the number of teams we are tied with, will be 1 if we aren't tied with anyone, otherwise will be what we divide by in the end
      const tiedWith = sortedScoresForGame.filter((val) => val.score === myScore.score);
      // Defaults to 1, unless we are actually tied
      const tiedWithTotal = tiedWith.length;
      // Get the number that we are tied with or beating (minus our own score)
      const tiedOrBeating = sortedScoresForGame.filter((val) => val.score === '' || parseFloat(val.score) <= parseFloat(myScore.score));
      const tiedOrBeatingTotal = tiedOrBeating.length - 1;
      let scoreNumerator = 0;
      for (let j = 0; j < tiedWithTotal; j++) {
        scoreNumerator += tiedOrBeatingTotal - j;
      }
      pointsForTeam += scoreNumerator / tiedWithTotal;
      sortedScoresForGame[indexInScoringList].points = pointsForTeam;
    });
    return sortedScoresForGame;
  }

  // Everyone else still gets credit for beating them!
  const calculateLeaderboard = () => {
    const newLeaderboard = [];
    for (let i = 1; i <= numGames; i++) {
      const gameKey = `game${i}`;
      const scoresForGame = scores[gameKey];
      const sortedScoresForGame = calculateScoresWithPointsForGame(scoresForGame);
      newLeaderboard.push({
        label: `Game ${i}`,
        gameKey,
        scores: sortedScoresForGame,
      });
    }
    return newLeaderboard;
  }

  // Whenever scores change, calculate the scores!
  useEffect(() => {
    if (!scores) {
      return;
    }
    
    const newLeaderboard = calculateLeaderboard();
    setLeaderboard(newLeaderboard);

  }, [scores]);


  // Function to save a series of scores for a game!
  // const saveScoresForGame = async (game, toSave) => {
  //   setLoading(true);
  //   try {
  //     const gameFromKey = game.replace('game', '');
  //     const scoresToSave = {
  //       password: getCenterAdminToken(),
  //       week,
  //       game: gameFromKey,
  //       product_id: productId,
  //       teams: calculateScoresWithPointsForGame(toSave),
  //     };
  //     await postApiRequest(`/center-admin/scoring/scores`, scoresToSave);
  //     // Update the leaderboard
  //     const newLeaderboard = calculateLeaderboard();
  //     const teamScores = calculatedSortedTeamScores(newLeaderboard);

  //     const pointsToSave = {
  //       password: getCenterAdminToken(),
  //       week,
  //       product_id: productId,
  //       teams: teamScores,
  //     };
  //     await postApiRequest(`/center-admin/scoring/week/points`, pointsToSave);
  //     // Save the leaderboard info
  //     setLeaderboard(newLeaderboard);
  //   } catch (err) {
  //     console.log(err, 'error saving scores');
  //   }
  //   setLoading(false);
  // };

  // Save all scores on the screen
  const saveAllScores = async (toSave) => {
    setLoading(true);
    try {
      const allScores = [];
      // Loop over the games and construct our items to save
      for (let i = 1; i <= numGames; i++) {
        const gameKey = `game${i}`;
        const scoresForGame = toSave[gameKey];
        const sortedScoresForGame = calculateScoresWithPointsForGame(scoresForGame);
        allScores.push({
          game: i,
          teams: sortedScoresForGame,
        });
      }
      const scoresToSave = {
        password: getCenterAdminToken(),
        week,
        product_id: productId,
        all: allScores,
      };
      await postApiRequest(`/center-admin/scoring/all-scores`, scoresToSave);

      // Update the leaderboard
      const newLeaderboard = calculateLeaderboard();
      const teamScores = calculatedSortedTeamScores(newLeaderboard);

      const pointsToSave = {
        password: getCenterAdminToken(),
        week,
        product_id: productId,
        teams: teamScores,
      };
      await postApiRequest(`/center-admin/scoring/week/points`, pointsToSave);
      // Save the leaderboard info
      setLeaderboard(newLeaderboard);
    } catch (err) {
      console.log(err, 'error saving scores');
    }
    setLoading(false);
  }


  // console.log(product?.name, numGames, week, teams, product?.center?.name, product?.league?.name, scores, 'product, games, week, teams, center, league, scores');
  // console.log(scores, 'scores');

  const title = loading ? '' : `${product?.name}, Week ${week}${week === '1' ? ' (Practice)' : ''} Scores`;

  return (
    <AdminContainer centerAdmin>
      <SEO title="Scoring" />
      <Heading title={title} />
      <Loadable loading={loading}>
        <ScorersTable numGames={numGames} scores={scores} teams={teams} updateScores={saveAllScores} />
        <LeaderBoard leaderboard={leaderboard} teams={teams} />
        <TotalScoreboard leaderboard={leaderboard} calculatedSortedTeamScores={calculatedSortedTeamScores} />
      </Loadable>
    </AdminContainer>
  );
}

export default CenterAdminScoringApp;
