import axios from 'axios';
import { navigate } from 'gatsby';
import { getCurrentJwt, logout } from './auth';

axios.interceptors.response.use(
  response => response.data,
  error => {
    if (error.response) {
      if (error.response.status === 401) {
        // We need to find a better way to handle this than just doing a window.redirect, I DON'T LIKE IT!
        logout(() => {
          navigate(`/sign-in`);
        });
        return;
      }
    }
    return Promise.reject(error);
  }
);

function getHeaders() {
  const headers = {};
  const token = getCurrentJwt();
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
}

function makeRequest(method, url, data) {
  const headers = getHeaders();
  if (method === 'get' || method === 'delete') {
    return axios({
      method,
      url,
      headers,
    });
  }
  return axios({
    method,
    url,
    data,
    headers,
  });
}


export const apiUrl = process.env.GATSBY_API_URL;
export const appUrl = process.env.GATSBY_SITE_URL;
export function getRequest(url) {
  return makeRequest('get', url);
};
export function postRequest(url, data) {
  return makeRequest('post', url, data);
}
export function putRequest(url, data) {
  return makeRequest('put', url, data);
}
export function deleteRequest(url) {
  return makeRequest('delete', url);
};

export function getApiRequest(url) {
  return makeRequest('get', `${apiUrl}${url}`);
};
export function postApiRequest(url, data) {
  return makeRequest('post', `${apiUrl}${url}`, data);
}
export function putApiRequest(url, data) {
  return makeRequest('put', `${apiUrl}${url}`, data);
}
export function deleteApiRequest(url) {
  return makeRequest('delete', `${apiUrl}${url}`);
};
